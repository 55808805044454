import React, { useEffect } from 'react';
import {
  Animated,
  Dimensions,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import Modal from 'react-native-modal';
import Text from '../Text';
import { View } from 'react-native-animatable';
import { Feather } from '@expo/vector-icons';

import axios from 'axios';
import Constants from 'expo-constants';
import themes from '_/constants/themes';
import { appConfig } from '_/config/app';
import { colors, defaultShadow, fontSizes } from '_/config/theme';
import { transparentize } from 'polished';
import { useUser } from '_/hooks/UserProvider';
import { useTranslation } from 'react-i18next';

interface AppVersionModalProps {
  appVersionData: { [key: string]: any } | null;
  setAppVersionData: (data: { [key: string]: any } | null) => void;
  updateAppVersion: () => Promise<void>;
}

const AppVersionModal: React.FC<AppVersionModalProps> = ({
  appVersionData,
  setAppVersionData,
  updateAppVersion,
}) => {
  const { t } = useTranslation();
  const { feathersApp } = useUser();

  const verifyAppVersion = async (data: any) => {
    const { appType, appSlug, latestVersion } = data || {};
    if (
      appType === 'app-host' &&
      appSlug === Constants.expoConfig?.slug &&
      latestVersion !== Constants.expoConfig?.version
    ) {
      setAppVersionData(data);
    }
  };

  useEffect(() => {
    feathersApp?.service('app-versions').on('patched', verifyAppVersion);

    return () => {
      feathersApp?.service('app-versions').off('patched', verifyAppVersion);
    };
  }, [feathersApp]);

  useEffect(() => {
    const checkAppVersionUpdates = async () => {
      try {
        const { data } = await axios.get(`${appConfig.apiUrl}/app-versions`, {
          params: {
            appType: 'app-host',
            appSlug: Constants.expoConfig?.slug,
          },
          headers: {
            authorization: Constants.expoConfig?.extra?.appVersionsSecret,
          },
        });
        const appVersionResponse = data?.data?.[0];

        if (
          appVersionResponse &&
          appVersionResponse.latestVersion !== Constants.expoConfig?.version
        ) {
          setAppVersionData(appVersionResponse);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    if (!__DEV__) checkAppVersionUpdates();
  }, []);

  if (!appVersionData) {
    return null;
  }

  const onReject = () => {
    setAppVersionData(null);
  };

  const onAccept = () => {
    if (appVersionData.isOtaUpdate || Platform.OS === 'web') {
      updateAppVersion();
    } else {
      const appLink =
        Platform.OS === 'ios'
          ? Constants.expoConfig?.extra?.appHostLinkIos
          : Constants.expoConfig?.extra?.appHostLinkAndroid;

      Linking.openURL(appLink as string);
    }
  };

  return (
    <Modal isVisible={!!appVersionData} style={styles.overlay}>
      <Animated.View style={styles.content}>
        <View style={styles.header}>
          <Text fontType="bold" center size={fontSizes.xl} color={colors.black}>
            <Feather name="alert-triangle" color={colors.warning} size={fontSizes.xl2} />{' '}
            {t('appVersionModal.title')}
          </Text>
        </View>
        <ScrollView>
          <View style={styles.subtitle}>
            <Text color={colors.black} size={fontSizes.lg}>
              {t('appVersionModal.description')}
            </Text>
            {appVersionData.showUpdateMessage && (
              <View style={styles.description}>
                <Text color={colors.dark1} size={fontSizes.md}>
                  <Feather name="help-circle" color={colors.black} size={fontSizes.md} />{' '}
                  {appVersionData.updateMessage}
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
        <View style={styles.buttons}>
          {!appVersionData.mandatoryUpdate && (
            <TouchableOpacity style={styles.button} onPress={onReject}>
              <Text color={colors.danger} size={fontSizes.lg} fontType="medium">
                {t('doItLater')}
              </Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity style={styles.button} onPress={onAccept}>
            <Text color={colors.dark3} size={fontSizes.xl} fontType="bold">
              {t('Update')}
            </Text>
          </TouchableOpacity>
        </View>
      </Animated.View>
    </Modal>
  );
};

export default AppVersionModal;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  header: {
    textAlign: 'center',
    paddingVertical: 18,
  },
  content: {
    backgroundColor: colors.white,
    width: width > height ? height : '85%',
    maxHeight: width > height ? '90%' : '70%',
    borderRadius: 12,
    borderColor: colors.light3,
    borderWidth: 1,
    ...defaultShadow,
  },
  subtitle: {
    alignItems: 'center',
    paddingHorizontal: 24,
    marginBottom: 12,
  },
  description: {
    alignItems: 'center',
    backgroundColor: colors.light3,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginVertical: 4,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    borderTopColor: colors.light3,
    borderTopWidth: 2,
  },
  button: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
  },
});
